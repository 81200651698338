<template>
  <div class="shorthome">
    <p>Youtube short is coming soon...</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.shorthome{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items:center;
}
</style>